import Head from 'next/head';
import React from 'react';
import styles from '../../styles/Blog.module.scss';
import { getAllPostsForHome } from '../../../lib/api';
import { Card, Hero, Section, BlogMajorCard, BlogMinorCard, BlogLayout, BlogSubscribe } from '../../components';

import Link from 'next/link';
import { NextSeo } from 'next-seo';

export default function Blog({ allPosts: { edges }, preview }) {
	const heroPost = edges[0]?.node;
	const morePosts = edges.slice(1);

	return (
		<>
			<Head>
				<link rel="icon" href="/favicon.ico" />
			</Head>

			<NextSeo
				title="Blog | IEG America"
				description="Get up to date with the latest news and articles by IEG"
				canonical="https://ieg-america.com/blog"
				openGraph={{
					title: 'Blog | IEG America',
					description: 'Get up to date with the latest news and articles by IEG',
					images: [
						{
							url: 'https://ieg-america.com/hero-d.jpg',
							alt: 'Featured image: IEG latest news and articles',
						},
					],
					// defaults
					// type: 'website',
					// locale: 'en_US',
					url: 'https://ieg-america.com/blog',
					// site_name: 'IEGAmerica',
				}}
				twitter={
					{
						// defaults
						// site: '@IEGAmerica',
						// cardType: 'summary',
					}
				}
			/>

			<Hero height="175px">
				{/* <h1 className="title"></h1> */}
				<h3 className="subtitle"></h3>
			</Hero>
			<Section>
				<BlogLayout>
					<div>
						<BlogMajorCard
							title={heroPost.title}
							excerpt={heroPost.customPreview.previewExcerpt}
							slug={heroPost.slug}
							date={heroPost.date}
							imageUrl={heroPost.featuredImage?.node.sourceUrl}
						/>
						<div className={styles.blogGrid}>
							{morePosts.map((post, i) => {
								const node = post?.node;

								return (
									<BlogMinorCard
										title={node.title}
										excerpt={node.customPreview.previewExcerpt}
										slug={node.slug}
										date={node.date}
										imageUrl={node.featuredImage?.node.sourceUrl}
										key={i}
									/>
								);
							})}
						</div>
					</div>
					<BlogSubscribe />
				</BlogLayout>

				<div style={{ textAlign: 'center', margin: '3rem 0' }}>
					<Link href="/blog/archives">
						<a>
							<h3 style={{ color: 'var(--primary)' }}>OLDER POSTS →</h3>
						</a>
					</Link>
				</div>

				{/* {morePosts.length > 0 && <span>{morePosts} </span>} */}
			</Section>
		</>
	);
}

export async function getStaticProps({ preview = false }) {
	const allPosts = await getAllPostsForHome(preview);
	return {
		props: { allPosts, preview },
	};
}
